// import React from "react";
// import SlimLayout from "../components/slimLayout";

// const Interstitial = () => (
//   <SlimLayout>
//     <p>K</p>
//     <script
//       async
//       src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
//     />
//     {/* <!-- Link ads --> */}
//     <ins
//       className="adsbygoogle"
//       style="display:block"
//       data-ad-client="ca-pub-1883784316422963"
//       data-ad-slot="5102685384"
//       data-ad-format="link"
//       data-full-width-responsive="true"
//     />
//     <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
//   </SlimLayout>
// );

// export default Interstitial;
import React from "react";

export default function interstitial() {
  return <div />;
}
